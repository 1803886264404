<template>
    <b-modal 
      id="modal-forgot-password"
      ref="modal"
      :title="title"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOK"
      centered
      ok-only
      auto-focus-button="ok"
      no-close-on-backdrop
      :hide-footer=hide
      :hide-header=hide>
        <template v-if="!valid && !inputCode">
            <b-form ref="form">
              <b-form-group
              :state="form.emailRegState"
              label-for="input-email-reg"
              :invalid-feedback="validityEmail">
              <b-form-input 
                  id="input-email-reg"
                  v-model="form.emailReg"
                  :state="form.emailRegState" 
                  :formatter="lowercaseFormat"
                  required
                  type="email"
                  placeholder="Masukan email terdaftar"></b-form-input>
                  <p>Kami akan mengirimkan kode untuk memulihkan kembali akun anda <span style="font-size:14px; color:#007bff" @click="inputCode = true, inputEmail = true, title='', txtSubmit='Verifikasi', form.emailRegState = null ">Masukan kode</span></p>
                  </b-form-group>
            </b-form>
        </template>
        <template #modal-footer>
            <b-button
                variant="primary"
                size="md"
                class="float-right"
                @click="onSubmitModal"
                style="color:#fff">
                <span v-show="!isLoad">{{txtSubmit}}</span>
                <b-spinner v-show="isLoad" small></b-spinner>
                </b-button>
        </template>
        <template v-if="valid && !inputCode">
          <span>Kode OTP sudah dikirim ke email anda! <a href="https://gmail.com/" target="_blank">buka email</a></span>
          <p>batas waktu penggunaan kode 5 menit <span style="font-size:14px; color:#007bff" @click="inputCode = true, hide = false, txtSubmit='Verifikasi'">Masukan kode</span></p>
        </template>
        <template v-if="inputCode">
            <b-form-group
              v-if="inputEmail"
              :state="form.emailRegState"
              label-for="input-email-reg"
              :invalid-feedback="validityEmail">
              <b-form-input 
                  id="input-email-reg"
                  v-model="form.emailReg"
                  :state="form.emailRegState" 
                  :formatter="lowercaseFormat"
                  required
                  type="email"
                  placeholder="Masukan email terdaftar"></b-form-input>
                  </b-form-group>
            <b-form ref="formCode">
              <b-form-group
              :state="form.otpCodeState"
              label-for="input-otp"
              :invalid-feedback="validityOTP">
              <b-form-input 
                  id="input-otp"
                  v-model="form.otpCode"
                  :state="form.otpCodeState" 
                  required
                  type="number"
                  placeholder="Masukan kode OTP"></b-form-input>
                  </b-form-group>
            </b-form>
        </template>
      </b-modal>
</template> 

<script> 
import {lowercaseFormat, validateEmail} from '@/lib/util/utilities.js'
import { mapActions } from "vuex"

export default {
  name: 'ForgotPassword',
  data() {
    return {
      title: 'Kesulitan untuk masuk ?',
      txtSubmit: 'Kirim Kode',
      valid: false,
      inputCode: false,
      hide: false,
      inputEmail: false,
      isLoad: false,
      form: {
        emailReg: '',
        emailRegState: null,
        otpCode: '',
        otpCodeState: null
      },
      message: {
        email: this.$root.message.validate.email,
        otp: this.$root.message.validate.otp
      }
    }
  },
  computed: {
        validityEmail() {
            return this.message.email
        },
        validityOTP() {
            return this.message.otp
        }
  },
  watch: {
    'form.emailReg': function() {
      if (this.form.emailReg.length > 0) {
        this.form.emailRegState = true
      }else{
        this.form.emailRegState = false
        this.message.email = this.$root.message.validate.email
      }
    },
    'form.otpCode': function() {
      if (this.form.otpCode.length > 0) {
        this.form.otpCodeState = true
      }else{
        this.form.otpCodeState = false
        this.message.email = this.$root.message.validate.email
      }
    }
  },
  methods: {
    ...mapActions(["VerifyOTP"]),
    lowercaseFormat,
    validateEmail,
    show() {
        this.$refs.modal.show()
    },
    checkEmailValid(valid){
      if (this.form.emailReg.length>0 && !validateEmail(this.form.emailReg)) {
          this.message.email = 'Format email tidak sesuai, mohon di cek kembali'
          valid = false
      }
      return valid
    },
    checkModalFormValidity() {
        var valid = this.$refs.form.checkValidity()
        valid = this.checkEmailValid(valid)
        this.form.emailRegState = valid
        return valid
    },
    checkModalFormCodeValidity() {
        var validEmail = false, validOtp = false
        if(this.form.emailReg.length>0){
          validEmail = true
          validEmail = this.checkEmailValid(validEmail)
        }
        if(this.form.otpCode.length>0)
          validOtp = true
          
        this.form.emailRegState = validEmail
        this.form.otpCodeState = validOtp
        return validEmail && validOtp
    },
    resetModal() {
      this.form.emailReg = '',
      this.form.emailRegState = null,
      this.form.otpCode = '',
      this.form.otpCodeState = null,
      this.valid = false
      this.inputCode = false
      this.inputEmail = false
      this.title = 'Kesulitan untuk masuk ?'
      this.txtSubmit = 'Kirim Kode'
      this.hide= false
    },
    handleOK(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.onSubmitModal()
    },
    async onSubmitModal() {
      this.isLoad = true
      if(!this.valid && !this.inputCode) {
        if (!this.checkModalFormValidity()) {
          this.isLoad = false
            return
        }
        await this.$root.api.sentAPI(
        {
          method: 'post',
          api: this.$root.api.post.forgotPassword,
          form: {
                email: this.form.emailReg,
            }
        })
        .then(res => {
          if(res.status.code === 404){
            this.form.emailRegState = false
            this.message.email = 'Email tidak terdaftar'
          }else if(res.status.code === 200){
            this.hide = true
            this.valid = true
            this.title = ''
          }
        })
        .catch((err) => {
            this.$root.isError = true
            if(!navigator.onLine) this.$root.isError = err.message
        })
      }else if(this.inputCode){
        if(!this.checkModalFormCodeValidity()) {
          this.isLoad = false
          return
        }
        const Form = new FormData();
        Form.append("email", this.form.emailReg);
        Form.append("otp", this.form.otpCode);
        
        await this.VerifyOTP(Form).then((res) => {
            if(res.status === 'Success') {
                this.$aes.setKey('qwertyqwerty')
                let encryptedEmail = this.$aes.encrypt(this.form.emailReg)

                this.$bvModal.hide('modal-forgot-password')
                this.$router.push({path: '/reset-password', query: {auth: res.token, target: encryptedEmail}})
            }
            else {
              this.form.otpCodeState = false
              this.message.otp = res.message
            }
        }).catch(err =>{
            console.log(err)
        })
      }
      this.isLoad = false
    }
  }
}
</script>

<style scoped>
p{
    color:#6A6A6A; 
    font-size:10px; 
    padding: 10px 0px 0px;
    cursor: pointer;
}

a{
  text-decoration: none;
}
</style>
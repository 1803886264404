import IconifyIcon from '@iconify/vue'

// import roundMail from '@iconify/icons-ic/round-mail'
// import shieldLockFill from '@iconify/icons-bi/shield-lock-fill'
import eye from '@iconify/icons-bi/eye-fill'
import eyeSlash from '@iconify/icons-bi/eye-slash-fill'

export const Iconify = IconifyIcon

// export const email = roundMail
// export const lock = shieldLockFill
export const eyeOpen = eye
export const eyeClosed = eyeSlash
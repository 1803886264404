<template>
    <b-modal 
      id="modal-reg-validation"
      ref="modal"
      title="Validasi Data Anggota"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOK"
      centered
      ok-only>
        <b-form ref="form">
            <b-form-group
                :state="form.aimsNoState"
                label-for="input-aims"
                :invalid-feedback="validityAims">
                <b-form-input 
                    id="input-aims"
                    v-model="form.aimsNo"
                    :state="form.aimsNoState"
                    required
                    type="number"
                    placeholder="No AIMS ..."></b-form-input>
            </b-form-group>
            <b-form-group
                :state="form.agencyState"
                label-for="input-agency"
                :invalid-feedback="validityAgency">
                <b-form-input 
                    id="input-agency"
                    v-model="form.agency"
                    :state="form.agencyState"
                    required
                    placeholder="Masukan Badan ..."
                    list="agency-list"></b-form-input>
                    <datalist id="agency-list">
                        <option v-for="agency in listAgency" :key="agency.code">{{agency.name}}</option>
                    </datalist>
            </b-form-group>
            <b-form-group
                :state="form.branchState"
                label-for="input-branch"
                :invalid-feedback="validityBranch">
                <b-form-input 
                    id="input-branch"
                    v-model="form.branch"
                    :state="form.branchState"
                    required
                    placeholder="Masukan Cabang ..."
                    list="branch-list"></b-form-input>
                    <datalist id="branch-list">
                        <option v-for="branch in listBranch" :key="branch.kode_cabang">{{branch.nama_cabang}}</option>
                    </datalist>
              </b-form-group>
        </b-form>
        <p v-if="message.verify !== null" style="color:red">{{ message.verify }}</p>
        <template #modal-footer>
            <b-button
                variant="primary"
                width="200"
                class="float-right"
                @click="onSubmitModal"
                style="color:#fff">
                    <span v-show="!isLoad">Selanjutnya</span>
                    <b-spinner v-show="isLoad" small></b-spinner>
            </b-button>
      </template>
      </b-modal>
</template>

<script>

import { mapActions } from "vuex";
import {lowercaseFormat, isEmpty} from '@/lib/util/utilities.js'
import agency from '@/lib/json/list-agency.json'

  export default {
    name: 'RegisterValidation',
    props: ['listBranch'],
    data() {
      return {
        form: {
            aimsNo: '',
            agency: '',
            agencyCode: '',
            branch: '',
            branchCode: '',
            agencyState: null,
            aimsNoState: null,
            branchState: null
        },
        message: {
            aims: this.$root.message.validate.aims,
            agency: this.$root.message.validate.tanzeem,
            branch: this.$root.message.validate.branch,
            verify: null
        },
        listAgency: agency.data,
        isLoad: false
      }
    },
    computed: {
        validityBranch() {
            return this.message.branch
        },
        validityAgency() {
            return this.message.agency
        },
        validityAims() {
            return this.message.aims
        }
    },
    watch: {
        'form.aimsNo': function() {
            this.message.verify = null
            if(this.form.aimsNo.length>0) {
                this.form.aimsNoState = true
            }else{
                this.form.aimsNoState = false
            }
        },
        'form.branch': function() {
            this.message.verify = null
            if(!this.checkBranchValidity()) {
                this.message.branch = 'Cabang ' + this.form.branch + ' tidak ada'
                if(isEmpty(this.form.branch)) this.message.branch = this.$root.message.validate.branch
                this.form.branchState = false
            }else{
                this.form.branchState = true
            }
        },
        'form.agency': function() {
            this.message.verify = null
            if(!this.checkAgencyValidity()) {
                this.message.agency = 'Badan ' + this.form.agency + ' tidak ada'
                if(isEmpty(this.form.agency)) this.message.agency = this.$root.message.validate.tanzeem
                this.form.agencyState = false
            }else{
                this.form.agencyState = true
            }
        }
        
    },
    methods: {
    ...mapActions(["Verify"]),
    show() {
        this.$refs.modal.show()
    },
    checkModalFormValidity() {
        if(this.form.agencyState == null) this.form.agencyState = !isEmpty(this.form.agency) ? true : false
        if(this.form.aimsNoState == null) this.form.aimsNoState = !isEmpty(this.form.aimsNoState) ? true : false
        if(this.form.branchState == null) this.form.branchState = !isEmpty(this.form.branchState) ? true : false
        
        var valid=false
        if (this.form.agencyState==true && this.form.aimsNoState==true && this.form.branchState==true) {
            valid=true
        }
        return valid
    },
    checkAgencyValidity() {
        var idx, valid = false
        for(idx in this.listAgency) {
            if(lowercaseFormat(this.form.agency) == lowercaseFormat(this.listAgency[idx].name)){
                this.form.agencyCode = this.listAgency[idx].code
                return valid = true
            }
        }
        return valid
    },
    checkBranchValidity() {
        var idx, valid = false
        for(idx in this.listBranch) {
            if(lowercaseFormat(this.form.branch) == lowercaseFormat(this.listBranch[idx].nama_cabang)){
                this.form.branchCode = this.listBranch[idx].kode_cabang
                return valid = true
            }
        }
        return valid
    },
    resetModal() {
      this.form.agency = '',
      this.form.aimsNo = '',
      this.form.branch = '',
      this.form.agencyState = null,
      this.form.aimsNoState = null,
      this.form.branchState = null
    },
    handleOK(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.onSubmitModal()
    },
    async onSubmitModal() {
        if (!this.checkModalFormValidity()) {
            return
        }
        this.isLoad = true
        const User = new FormData();
        User.append("branch", this.form.branchCode);
        User.append("noAims", this.form.aimsNo);
        User.append("tanzeem", this.form.agencyCode);
        await this.Verify(User).then((res) => {
            if(res.member) this.message.verify = this.$root.message.result.alreadyMemberBufu
            else if(res.status === 200) {
                this.$bvModal.hide('modal-reg-validation')
                this.$router.push('/register')
            }
            else this.message.verify = this.$root.message.result.notMemberAims
        }).catch(err =>{
            console.log(err)
        })
        this.isLoad = false
    }
    }
  }
</script>
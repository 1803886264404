<template>
  <div style="background:white" class="h-100">
    <v-main>
      <v-container>
        <template v-if="!$root.isMobile">
          <v-card
            class="mx-auto my-15 pa-5"
            max-width="400"
            elevation="3">
                <v-row class="d-flex justify-content-center">
                  <!-- <b-col class="d-none d-sm-block" md="12"> -->
                  <v-col md="12">
                    <img alt="Bufu logo" src="../assets/bufu-logo-load.png" width="150">
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-content-center">
                  <v-col md="12" >
                    <b-form @submit="onSubmit">
                      <v-col md="12">
                        <b-form-input 
                            id="input-email"
                            v-model="form.email"
                            type="email"
                            required
                            placeholder="Masukan Email"></b-form-input>
                      </v-col>
                      <v-col md="12">
                        <!--<h1 :class="`text-subtitle-1 font-weight-light mb-n1 mt-n3`" style="text-align:right" @click="showPass = !showPass">{{showPass ? 'sembunyikan':'tampilkan'}}</h1>-->
                        <b-input-group>
                          <b-form-input 
                            id="input-password"
                            v-model="form.password"
                            :type="showPass ? 'text':'password'"
                            required
                            placeholder="Masukan Kata Sandi"></b-form-input>
                          <b-input-group-append>
                            <b-input-group-text>
                              <span @click="showPass = !showPass">
                                <Iconify :icon="showPass?icons.eyeOpen:icons.eyeClosed" :style="{color: '#575859'}" />
                              </span>
                            </b-input-group-text>
                          </b-input-group-append>
                        </b-input-group>
                      </v-col>
                      <p class="forgot-password" @click="showModalForgotPwd">Lupa Kata Sandi?</p>
                    <v-btn type="submit" color="primary" rounded dark width="200">
                      <span v-show="!isLoad">Masuk</span>
                      <b-spinner v-show="isLoad" small></b-spinner>
                    </v-btn>
                  <p v-if="message !== null" style="color:red; margin-top:20px">{{ message }}</p>
                  </b-form>
                  </v-col>
                </v-row>
          </v-card>
          <p style="font-size:18px">Belum punya akun KBUFU?<v-btn @click="showModalReg" text color="primary">Daftar sekarang</v-btn></p>
        </template>
        <template v-if="$root.isMobile">
            <v-row class="d-flex justify-content-center">
              <!-- <b-col class="d-none d-sm-block" md="12"> -->
              <v-col md="12">
                <img alt="Bufu logo" src="../assets/bufu-logo-load.png" width="200">
              </v-col>
            </v-row>
            <v-row class="d-flex justify-content-center">
              <v-col md="12" >
                <b-form @submit="onSubmit">
                  <v-col md="12">
                    <b-form-input 
                        id="input-email"
                        v-model="form.email"
                        size="lg"
                        type="email"
                        required
                        placeholder="Masukan Email"></b-form-input>
                  </v-col>
                  <v-col md="12">
                    <!--<h1 :class="`text-subtitle-1 font-weight-light mb-n1 mt-n3`" style="text-align:right" @click="showPass = !showPass">{{showPass ? 'sembunyikan':'tampilkan'}}</h1>-->
                    <b-input-group>
                      <b-form-input 
                        id="input-password"
                        v-model="form.password"
                        :type="showPass ? 'text':'password'"
                        size="lg"
                        required
                        placeholder="Masukan Kata Sandi"></b-form-input>
                      <b-input-group-append>
                        <b-input-group-text>
                          <span @click="showPass = !showPass">
                            <Iconify :icon="showPass?icons.eyeOpen:icons.eyeClosed" :style="{color: '#575859'}" />
                          </span>
                        </b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                  </v-col>
                  <p class="forgot-password" @click="showModalForgotPwd">Lupa Kata Sandi?</p>
                <v-btn type="submit" color="primary" rounded dark width="100%" large>
                  <span v-show="!isLoad">Masuk</span>
                  <b-spinner v-show="isLoad" small></b-spinner>
                </v-btn>
              <p v-if="message !== null" style="color:red; margin-top:20px">{{ message }}</p>
              </b-form>
              </v-col>
            </v-row>
            <p style="font-size:18px">Belum punya akun KBUFU?<v-btn @click="showModalReg" text color="primary">Daftar sekarang</v-btn></p>
        </template>
        <ForgotPassword ref="forgotPasswordModal" />
        <RegisterValidation ref="registerValModal" :listBranch=listBranch />
        <OverlayProgress :overlay=$root.overlay :isError=$root.isError />
        <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          absolute
        >
          Email berhasil diganti, silahkan login kembali 
        </v-snackbar>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import {Iconify, eyeOpen, eyeClosed} from '@/lib/icons.js'
import {contactWA, contactEmail, contactPhone} from '@/lib/util/utilities.js'

import ForgotPassword from '@/components/modal/ForgotPassword.vue'
import RegisterValidation from '@/components/modal/RegisterValidation.vue'
import OverlayProgress from '@/components/OverlayProgress.vue'


export default {
  name: 'Login',
  components: {
    Iconify,
    ForgotPassword,
    RegisterValidation,
    OverlayProgress
  },
  data() {
    return {
      icons: {
        eyeOpen,
        eyeClosed
      },
      form: {
        email: this.$store.getters.stateUser !== null ? this.$store.getters.stateUser : '',
        password: ''
      },
      message: null,
      isLoad: false,
      listBranch: [],
      snackbar: false,
      timeout: 3000,
      showPass: false
    }
  },
  watch: {
    'form.email': function() {
        this.message = null
    },
    'form.password': function() {
        this.message = null
    }
  },
  methods: {
    contactWA, contactEmail, contactPhone,
    ...mapActions(["LogIn"]),
    async onSubmit(evt) {
      this.isLoad = true
      evt.preventDefault()

      const User = new FormData();
      User.append("username", this.form.email);
      User.append("password", this.form.password);
      
      await this.LogIn(User).then(res => {
        if(res.status === 200) {
            this.$router.push("/");
        }else{
            this.message = res.message
        }
      }).catch(err => {
        console.log(err)
      })
      this.isLoad = false
    },
    showModalForgotPwd() {
      this.$refs.forgotPasswordModal.show()
    },
    showModalReg() {
      this.$refs.registerValModal.show()
    }
  },
  async mounted() {
    if(this.$store.getters.changeEmail){
      this.snackbar = true
      this.$store.commit('changeEmail', false)
    }
    await this.$root.api.sentAPI(
      {
        method: 'get',
        api: this.$root.api.get.branch
      })
      .then(res => {
        this.listBranch = res.data
      })
      .catch((err) => {
            this.$root.isError = true
            if(!navigator.onLine) this.$root.isError = err.message
      })
      .finally(() => {
        if(!this.$root.isError) this.$root.overlay = false
      })
    this.$nextTick(function () {})
    if(this.$router.currentRoute.hash === '#register') {
      this.showModalReg()
    }
  }
}
</script>

<style scoped>
a{
  text-decoration: none;
}
.forgot-password{
  text-align: left;
  padding: 0px 12px 0px;
  outline: none;
  cursor: pointer;
  color: #008AD8;
}
p{
  font-size: 12px
}
table{
  margin-top:20px;;
  color: #008AD8;
}

.v-application .text-subtitle-1{
  font-size: 12px !important;
  cursor: pointer;
}
</style>